.filter-button {
  cursor: auto !important;
  background-color: #f0f0f0 !important;
}

.header-container {
  z-index: 1300 !important;
  position: sticky;
  padding: 4rem 5.5rem;
  padding-bottom: 0;
  padding-top: 5px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.header-container .first-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-container .first-row {
  margin-bottom: 1rem;
  width: 30%;
}

.header-container .second-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-container button,
.header-container button:focus {
  border: none;
  outline: none;
  font-family: 'Roboto', sans-serif;
}

.filter-button-container {
  display: flex;
  padding-right: 0.7rem;
}

.header-container .filter-button-container button {
  color: rgba(51, 51, 51, 0.8);
  font-weight: normal;
  font-style: normal;
  padding: 12px 23px;
  border-radius: 2px;
  margin-right: 16px;
  transition: all 200ms cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

.header-container .filter-button-container .critical-active {
  background: #99baeb !important;
  color: #ffffff;
}

.header-container .filter-button-container .non-critical-active {
  background: #ff7b7b !important;
  color: #ffffff;
}

.header-container .filter-button-container .control {
  background: #e6eefa !important;
  color: #ffffff;
}

.header-container .diagram-toggle-container button {
  background: transparent;
}

.legend-box {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.3rem;
}

.legend-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.legend {
  display: flex;
  gap: 1.5rem;
}

.setting{
  display: flex;
  align-items: center;
}

.setting-icon:hover {
  cursor: pointer
}

.zoom-icon:hover {
  cursor: pointer
}

.setting-selected-color {
  background: #e6eefa!important
}