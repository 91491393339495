.zoom-percentage-container {
    color: #A4B0C7;
    font-family: 'Roboto';
    background: #fff;
    border: 1px solid #A4B0C7;
}

.zoom-percentage-container p {
    font-size: 0.8rem;
    padding: 2px 5px;
    margin-bottom: 0;
}

.loading span {
    font-size: 50px;
}

.scale {
    scale: 1.01;
    margin-top: .65rem;
}

.diagram-zoom-controls {
    width: 100%;
    height: 50px;
    top: 0px;
    right: 0px;
    z-index: 00;
    display: flex;
    justify-content: row-reverse;
    padding: 0rem 2.7rem;
    margin-top: -0.7rem;
}

.zoom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 47px;
    color: white;
    width: 100%;
    height: 100%;
    gap: 5px;
}

.zoom-icon {
    background-color: #A4B0C7;
    padding: 3px;
}