.dropdown-container {
  position: relative;
  display: block;
  width: 63%;
  height: 46px;
  z-index: 3000;
  /* outline: 1px solid blue; */
}

.dropdown-container .dropdown-list {
  /* outline: 1px solid red; */
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 16px;
  background: #f7f7f7;
  border-radius: 3px;
  transition: 50ms;
  max-height: 350px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-container .dropdown-list-active {
  border: 2px solid rgba(51, 51, 51, 0.8);
}

.dropdown-container .dropdown-list .dropdown-list-item {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding: 5px 0px;
  width: 100%;
  line-height: 2rem;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
}

.dropdown-container
  .dropdown-list
  .dropdown-list-item
  .dropdown-list-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 89%;
}

.dropdown-container .dropdown-list-inactive-item {
  border: none !important;
}

.dropdown-container .dropdown-list .dropdown-list-active-item {
  position: sticky;
  top: 0;
  left: 0;
  background: #f7f7f7;
  font-weight: bold;
  color: rgba(51, 51, 51, 0.8);
  font-size: 16px;
  border-bottom: 1px solid #727275;
}

.border-none {
  border: none !important;
}

.disabled {
  opacity: .6;
  cursor: default!important;
  color: rgba(51, 51, 51, 0.6)!important;
}

.disabled:hover {
  style: none;
}

.dropdown-container
  .dropdown-list
  .dropdown-list-active-item
  .dropdown-list-item-image {
  position: absolute;
  right: 0;
}

.dropdown-container
  .dropdown-list
  .dropdown-list-active-item
  .dropdown-list-item-inactive-image {
  transform: rotateZ(180deg);
}

.dropdown-container
  .dropdown-list
  .dropdown-list-item
  .dropdown-list-item-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 1rem;
}

.dropdown-container
  .dropdown-list
  .dropdown-list-item
  .dropdown-list-item-label {
  transition: 100ms;
}

.dropdown-container
  .dropdown-list
  .dropdown-list-item:hover
  .dropdown-list-item-label {
  color: rgba(51, 51, 51, 0.6);
}

.loading-more {
  margin-top: 2rem
}

.loading-size span {
  font-size: .5rem
}

.new-diagram-indicator {
  background: #fff
}