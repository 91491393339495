.modal-action-container {
    display: flex;
    flex-direction: column;
}


.modal-action-icon-row {
    position: absolute;
    right: 4%;
    top: 4%;
    cursor: pointer;
}

.modal-action-button-row {
    padding: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
    transform: translateY(20%);
}

.modal-action-text {
    padding: 1rem 0;
}