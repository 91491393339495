.rectangular-container {
  cursor: pointer;
  position: relative;
}

.rectangular-container .external-link-container {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 3000;
}


.rectangular-container .delete-link-container {
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 3000;
}

.rectangular-container .draft-marker {
  position: absolute;
  bottom: -15%;
  left: -6%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
  z-index: 5000;
  border-width: 3px;
}

.rectangular-container .circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1px solid rgb(153, 186, 235);
  z-index: -10;
  padding: 0;
  display: inline-block;
}

.rectangular-container .circle-right {
  margin-right: -10px;
}

.rectangular-container .line {
  position: absolute;
  height: 23px;
  width: 2px;
}

.rectangular-container .circle-left {
  margin-left: -10px;
}
.line-clamp p a {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}


.rectangular-container .global-icon-container {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6000;
  transform: translate(-50%, 50%);
}
