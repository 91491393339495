.multi-line-container {
  padding: 1rem;
}

.single-element-line-container {
  margin-bottom: 8rem;
}

.multi-line-container .single-element-line-container:last-child {
  margin-bottom: 0rem;
}

.multi-line-container .line-rectangular-container {
  margin-right: 1rem;
  margin-left: 1rem;
}

[class~='multi-element-line-container']:last-of-type {
  margin-bottom: 47px !important;
}

.textarea-container {
  position: relative;
  height: 51px;
  z-index: 9000;
}

.record-item-list-container {
  position: absolute;
  top: 100%;
  left: -20%;
  width: 150%;
  max-height: 150px;
  overflow: auto;
  z-index: 9000;
  background: #f7f7f7;
  box-shadow: 10px 20px 23px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 1rem;
}

.text-area-add-button {
  position: absolute;
  z-index: 9000;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #f08413;
  align-items: center;
}

.text-area-add-button * {
    margin-right: 10px;
}

.text-area-add-button p {
    margin-bottom: 0;
    margin-right: 2px;
}


.record-item-list-container .record-item p {
  margin: 0;
  margin-left: 1rem;
}


.record-item-list-container .record-item {
  display: flex;
  align-items: center;
}