.record-item-list-container {
  position: absolute;
  top: 100%;
  left: -22%;
  width: 150%;
  max-height: 150px;
  overflow: auto;
  z-index: 9000;
  background: #f7f7f7;
  box-shadow: 10px 20px 23px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 0.5rem 0;
}

.text-area-add-button {
  position: absolute;
  z-index: 9000;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #f08413;
  align-items: center;
}

.text-area-add-button * {
  margin-right: 10px;
}

.text-area-add-button p {
  margin-bottom: 0;
  margin-right: 2px;
}

.record-item-list-container .record-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 1rem;
  word-break: break-word;
  text-align: left;
}

.record-item-list-container .record-item p {
  margin: 0;
  margin-left: 0.5rem;
}

.record-item-list-container .record-item:hover {
  background-color: #F0F0F0;
}

.record-item-active {
  background-color: #F0F0F0;
}

.record-item-list-container .record-item:last-child {
  margin-bottom: 0;
}

.box-loading-controls {
  position: absolute!important;
  top: 4.3rem!important;
  z-index: 9999!important;
}

.box-loading-causes {
  position: relative!important;
  bottom: 6.6rem!important;
  left: 2.4rem;
}

.r05 {
  right: 0.5rem!important;
}

.r3 {
  right: 3rem!important;
}

.r3-3 {
  right: 3.3rem!important;
}

.r3-7 {
  right: 3.7rem!important;
}

.w200 {
  width: 200px!important;
}

.w120 {
  width: 120px!important;
}